@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;

}

body {

  background: black;

}
h1, h4, p,
a{
  color: white;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: rgb(217, 97, 12);
  color: black;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 10px;
}

.btn-light{
  margin-left: 10px;
  background: transparent;
  color: white;
  border-color: rgb(253, 253, 253);
}

.btn:hover{
  background: rgb(210, 148, 93);
  color:white;
  transition: 0.3s;
}



