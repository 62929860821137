* {
    box-sizing: border-box;
  }
  
  /* Set a background color */
  body {
    background-color: #060606;
    font-family: Helvetica, sans-serif;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: -10px;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  /* Container around content */
  .container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
   
  }
  
  /* The circles on the timeline */
  .container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -13%;
    background-color: white;
    border: 4px solid #FF9F55;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    margin-left: -9%;
  }
  
  /* Place the container to the left */
  .left {
    left: -30%;
  }
  
  /* Place the container to the right */
  .right {
    left: 30%;
  }
  
  /* Add arrows to the left container (pointing right) */
  
  
  /* Add arrows to the right container (pointing left) */
  
  /* Fix the circle for containers on the right side */
  .right::after {
    left: -16px;
  }
  
  /* The actual content */
  .content {
    padding: 5px 5px;
    background-color: white;
    position: relative;
    border-radius: 6px;
  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 300px) {
  /* Place the timelime to the left */
    .timeline::after {
        left: 260px;
        bottom: 10;
    }
    
  
  /* Full-width containers */
    .container {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    } 
  
  /* Make sure that all arrows are pointing leftwards */
    

  .education{
    text-align: center;
  }
  
  /* Make sure all circles are at the same spot */
    .left::after, .right::after {
      left: 25%;
    }
  
  /* Make all right containers behave like the left ones */
    .right {
      left: 60%;
    }

   
  }

  .content2{
    font-size: 1.2rem;
    text-align: center;
  }


  .year{
    font-size: 0.7rem;
    color: rgb(131, 126, 126);
   
    text-align: center;
  }

  .inside{
    font-size: 0.8rem;
    color: rgb(0, 0, 0);
    text-align: center;
  }

  .education{
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 3%;
    text-decoration: underline;
  }

  .place{
    color: black;
    font-size: 0.8rem;
    text-align: center;
  }

  .inside2{
    font-size: 0.8rem;
    color: rgb(0, 0, 0);
    
  }

  .desig{
    font-size: 0.8rem;
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
  .Buttons{
    margin-left: 4%;
  }