.mask{
    width: 100%;
    height: 100vh;
    position: relative;
}
.intro-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}
.mask::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.8;
}
.hero{
    height: 100%;
    width: 100%;
    
}
.hero .contenti{
 position: absolute;
 transform: translate(-50%, -50%) ;
 top:50%;
 left: 50%;
 text-align: center;
}

.hero .contenti h1{
    font-size: 4rem;
}

.hero .contenti p{
    font-size: 1.5rem;
    font-weight: 200;
}
